// This will hold us over until we can convert the rest of the site to Ember

(function() {

  function SawmillAdapter(lib) {
    this.lib = lib;

    this.trackEvent = function(event) {
      this.lib.track(event.action, {
        label: event.category + "-" + event.label,
        url: document.location.href,
        ref_url: document.referrer
      });
    };

    this.trackPageView = function() {
      this.lib.page(document.location.href, {ref_url: document.referrer});
    };

  }

  function GAAdapter(lib) {
    this.lib = lib;

    this.trackEvent = function(event) {
      var eventOptions = {};

      eventOptions.eventCategory = event.category;
      eventOptions.eventAction = event.action;
      eventOptions.eventLabel = event.label;
      eventOptions.eventValue = event.value;

      this.lib('send', eventOptions);
    };

    this.trackPageView = function() {
      this.lib('send', 'pageview');
    };
  }

  // Assumes all tracking libraries have been initialized before passed in
  function SimpleTracker(trackers) {
    if (!(this instanceof SimpleTracker)) {
      return new SimpleTracker(trackers)
    }

    this._adapters = [];

    if (trackers) {
      if (trackers.ga) { this._adapters.push(new GAAdapter(trackers.ga)); }
      if (trackers.sawmill) { this._adapters.push(new SawmillAdapter(trackers.sawmill)) }
    }
  };

  SimpleTracker.prototype.trackEvent = function(event) {
    this._adapters.forEach(function(adapter) {
      adapter.trackEvent(event);
    });
  };

  SimpleTracker.prototype.trackPageView = function() {
    this._adapters.forEach(function(adapter) {
      adapter.trackPageView();
    });
  };

  // TODO: PageLoad with performance metrics if we want it

  window.SimpleTracker = SimpleTracker;
}());
;